import dayjs from 'dayjs';
import { deburr, isEmpty, padStart, sortBy, toPairs, groupBy, compact } from 'lodash-es';
import { hotelFacilityIconMap, hotelRoomFacilityIconMap } from '@airpaz/apz-js';
import {
  MIN_DISPLAY_REVIEW_COUNT,
  MIN_DISPLAY_REVIEW_RATING,
  MIN_DISPLAY_TRIPADV_REVIEW_COUNT,
  MIN_DISPLAY_TRIPADV_REVIEW_RATING,
} from '../config/hotel-defaults';
import { HotelBookOrderDetail } from '#types/book';
import {
  Hotel,
  HotelRoom,
  HotelRoomCancellationInfo,
  HotelRoomIncrementalPrice,
  HotelGeneralFacility,
  HotelRoomIncrementalPriceDeal,
  HotelSpecialRequest,
} from '#types/hotel';

import { useDateFormatter, useCurrencyFormatter } from '#imports';

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

const TOP_FACILITIES_IDS = [34, 219, 55, 42, 213, 37, 48, 200, 35, 91, 6, 105, 4, 43, 271, 2];

export const countTotalRoom = (orders: HotelBookOrderDetail[]) => {
  return orders.reduce((n, order) => {
    const countRoom = order.rooms.reduce((m, room) => m + room.countRoom, 0);
    return +n + countRoom;
  }, 0);
};

export const sanitizeHotelName = (name: string) => {
  return deburr(name)
    .replace(/[\u0300-\u036F]|\(|\)|\[|\]|\{|\}|,/g, '')
    .replace(/\s*([^a-z0-9\u4E00-\u9FAF\u3040-\u309F\u30A0-\u30FF\u3131-\uD79D\u0E00-\u0E7F\u0600-\u06FF]+)\s*/gi, '-')
    .replace(/^-+|\s|\.|\\|\/|'/gi, '');
};

export const isHotelListFilterActive = (filters: object) =>
  Object.values(filters).some((value) => typeof value === 'number' || !isEmpty(value));

export const showReviews = (
  {
    rating,
    count,
  }: {
    rating: number;
    count: number;
  } = { rating: 0, count: 0 },
) => {
  return rating > MIN_DISPLAY_REVIEW_RATING && count > MIN_DISPLAY_REVIEW_COUNT;
};

export const showTripAdvisorReviews = (
  {
    rating,
    count,
  }: {
    rating: number;
    count: number;
  } = { rating: 0, count: 0 },
) => {
  return rating > MIN_DISPLAY_TRIPADV_REVIEW_RATING && count > MIN_DISPLAY_TRIPADV_REVIEW_COUNT;
};

export const getHotelAddress = (
  hotel: Optional<Pick<Hotel, 'address' | 'city' | 'district' | 'zipCode'>, 'district' | 'city' | 'zipCode'>,
) => {
  let address = hotel.address;

  if (hotel.district) {
    address += `, ${hotel.district.name}`;
  }

  if (hotel.city) {
    address += `, ${hotel.city.name}`;
  }

  if (hotel.zipCode) {
    address += ` ${hotel.zipCode}`;
  }

  return address;
};

export const getStayNights = (checkIn: dayjs.ConfigType, checkOut: dayjs.ConfigType) =>
  Math.ceil(dayjs(checkOut).diff(dayjs(checkIn), 'hours') / 24);

export const getSpecialRequestArrivalOptions = () => {
  const arrivalOptions = [];
  const getTimeString = (n: number) => `${padStart(n + '', 2, '0')}.00 - ${padStart(n + 1 + '', 2, '0')}.00`;

  for (let i = 0; i < 26; i++) {
    arrivalOptions.push(getTimeString(i < 24 ? i : i - 24));
  }

  return arrivalOptions;
};

export const stringifySpecialRequest = ({
  selectedChecks,
  message,
  selectedArrival,
  checkInDate,
}: {
  selectedChecks: string[];
  message: string;
  selectedArrival: number;
  checkInDate: string;
}) => {
  const formatDate = useDateFormatter();

  if (!(selectedChecks.length > 0 || !!message || selectedArrival > -1)) {
    return '';
  }

  const specialReqArrivalOptions = getSpecialRequestArrivalOptions();
  const checkStr = selectedChecks.reduce(
    (str, key, index, arr) => (str += index < arr.length - 1 ? `${key},` : key),
    '',
  );
  const msgStr = message.replace(/;/gi, '');

  let arrivalStr = specialReqArrivalOptions[selectedArrival] || '';

  if (arrivalStr) {
    arrivalStr += +selectedArrival > 23 ? ` (${formatDate(dayjs(checkInDate).add(1, 'day'))})` : '';
  }

  return `${checkStr};${msgStr};${arrivalStr};${selectedArrival}`;
};

export const parseSpecialRequest = (specialRequest: string): HotelSpecialRequest | undefined => {
  if (!specialRequest) {
    return;
  }

  const [selectedChecks, message = '', selectedArrivalStr = '', selectedArrival = -1] = specialRequest.split(';');

  const newSelectedChecks = selectedChecks?.split(',').filter((check) => !!check);

  return {
    selectedChecks: newSelectedChecks ?? [],
    message,
    selectedArrivalStr,
    selectedArrival: +selectedArrival,
  };
};

export const getRoomPriceData = (room: HotelRoom, count: number): HotelRoomIncrementalPrice | undefined => {
  if (count < 1) {
    count = 1;
  }

  return room.incrementalPrice[count - 1];
};

export const getRoomLocalPriceData = (room: HotelRoom, count: number): HotelRoomIncrementalPrice | undefined => {
  if (count < 1) {
    count = 1;
  }

  return room.hotelCurrency[count - 1];
};

export const getRoomPrice = (room: HotelRoom, count: number) => {
  const priceData = getRoomPriceData(room, count);

  return priceData?.price ?? room.price * count;
};

export const getRoomLocalPrice = (room: HotelRoom, count: number) => {
  const priceData = getRoomLocalPriceData(room, count);

  return priceData?.price ?? room.price * count;
};

export const getRoomDeal = (room: HotelRoom, count: number) => {
  const priceData = getRoomPriceData(room, count);

  if (priceData?.deal) {
    (priceData.deal as HotelRoomIncrementalPriceDeal & { disc: number }).disc = Math.ceil(
      ((priceData.deal.valueBeforeDiscount - priceData.price) / priceData.deal.valueBeforeDiscount) * 100,
    );
  }

  return (priceData?.deal as HotelRoomIncrementalPriceDeal & { disc?: number }) ?? null;
};

export const isRoomHaveDeal = (room: HotelRoom) => {
  return room.incrementalPrice.some((price) => price.deal);
};

export const isPayAtHotel = (payMethods: string[]) => payMethods.some((method) => /payathotel/i.test(method));

export const isPayNow = (payMethods: string[]) => payMethods.some((method) => /paynow/i.test(method));

export const isPayLater = (payMethods: string[]) => payMethods.some((method) => /paylater/i.test(method));

export const getRoomFreeCancellation = (cancellationInfos: HotelRoomCancellationInfo[]) =>
  cancellationInfos.find((c) => c.fee === 0);

export const hasFreeCancellation = (cancellationInfos: HotelRoomCancellationInfo[]) =>
  !isEmpty(getRoomFreeCancellation(cancellationInfos));

export const getRatingScale = (rating: number) => {
  if (rating > 9) return 'hotel.rating_4';
  else if (rating > 8) return 'hotel.rating_3';
  else if (rating > 7) return 'hotel.rating_2';
  else if (rating > 6) return 'hotel.rating_1';
  else if (rating > 5) return 'hotel.rating_5';
  else if (rating > 4) return 'hotel.rating_6';
  else if (rating > 3) return 'hotel.rating_7';
  else return 'hotel.rating_8';
};

export const getFacilities = (facilities: HotelGeneralFacility[], type: 'hotel' | 'room' = 'hotel') => {
  const fIconMap = type === 'hotel' ? hotelFacilityIconMap : hotelRoomFacilityIconMap;

  return sortBy(toPairs(groupBy(compact(facilities), 'group')), 0).map((group) => ({
    title: group[0],
    facilities: group[1].map((facility) => ({
      ...facility,
      icon: fIconMap[String(facility.facilityId) as keyof typeof fIconMap],
    })),
  }));
};

export const getTopFacilities = (
  facilities: HotelGeneralFacility[],
  count: number,
  type: 'hotel' | 'room' = 'hotel',
) => {
  const fIconMap = type === 'hotel' ? hotelFacilityIconMap : hotelRoomFacilityIconMap;

  const topFacilities = compact(facilities)
    .filter((facility) => TOP_FACILITIES_IDS.find((id) => id === facility.facilityId))
    .slice(0, count)
    .map((facility) => ({
      ...facility,
      icon: fIconMap[facility.facilityId.toString() as keyof typeof fIconMap] || 'check',
    }));

  if (topFacilities.length < count) {
    for (let i = 0; i < count - topFacilities.length; i++) {
      if (!!facilities[i] && !TOP_FACILITIES_IDS.includes(facilities[i]!.facilityId))
        topFacilities.push({
          ...facilities[i]!,
          icon: fIconMap[facilities[i]!.facilityId.toString() as keyof typeof fIconMap] || 'check',
        });
    }
  }

  return topFacilities;
};

export const getRoomCancelationInfo = (info: HotelRoomCancellationInfo) => {
  const formatDate = useDateFormatter();
  const formatCurrency = useCurrencyFormatter();

  return {
    date: `${formatDate(normalizeDateTimezone(info.from, info.timezone), 'LLL')} - ${formatDate(
      normalizeDateTimezone(info.until, info.timezone),
      'LLL',
    )}`,
    price: formatCurrency(info.fee, info.currency),
  };
};

export const trimHotelMetaTitle = (
  title = '',
  /* Recommended title length for SEO */
  length = 70,
) => {
  const titleLength = title ? title.length : 0;

  if (titleLength > length) {
    const strToTrim = title.substring(title.lastIndexOf('|') + 1, title.indexOf('Airpaz') - 1);

    return title.replace(strToTrim, '');
  }

  return title;
};

export const parseHotelCitySlug = (slug: string) => {
  const slugParts = slug.split('-');

  if (slugParts.length >= 2) {
    const city = slugParts.slice(0, -1).join('-');
    const country = (slugParts[slugParts.length - 1] as string).toString().toUpperCase();

    return { city, country };
  } else {
    return null;
  }
};

export const parseHotelRegionSlug = (slug: string) => {
  const slugParts = slug.split('-');

  if (slugParts.length >= 2) {
    const region = slugParts.slice(0, -1).join('-');
    const country = (slugParts[slugParts.length - 1] as string).toString().toUpperCase();

    return { region, country };
  } else {
    return null;
  }
};

export const parseHotelDistrictSlug = (slug: string) => {
  const slugParts = slug.split('-');

  if (slugParts.length >= 2) {
    const district = slugParts.slice(0, -1).join('-');
    const country = (slugParts[slugParts.length - 1] as string).toString().toUpperCase();

    return { district, country };
  } else {
    return null;
  }
};
